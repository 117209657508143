require("./src/styles/index.css");
const { ThemeProvider, CssBaseline } = require("@mui/material");
const React = require("react");
const Layout = require("./src/components/Layout").default;
const { iframedBlogVizTheme } = require("./src/theme");

exports.wrapPageElement = ({ element, props }) => {
  // HACK. for now we use blog pages just to render graphics to be iframed into the old blog site
  if (props.uri.startsWith("/blog/")) {
    return (
      <ThemeProvider theme={iframedBlogVizTheme}>
        <CssBaseline />
        {element}
      </ThemeProvider>
    );
  }

  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};
