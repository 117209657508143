import React from "react";
import clsx from "clsx";

const Instagram = ({ className, ...props }) => {
  return (
    <svg
      className={clsx("icon", "icon--instagram", className)}
      viewBox="0 0 384 448"
      {...props}
    >
      <path d="M256 224c0-35.25-28.75-64-64-64s-64 28.75-64 64 28.75 64 64 64 64-28.75 64-64zM290.5 224c0 54.5-44 98.5-98.5 98.5s-98.5-44-98.5-98.5 44-98.5 98.5-98.5 98.5 44 98.5 98.5zM317.5 121.5c0 12.75-10.25 23-23 23s-23-10.25-23-23 10.25-23 23-23 23 10.25 23 23zM192 66.5c-28 0-88-2.25-113.25 7.75-8.75 3.5-15.25 7.75-22 14.5s-11 13.25-14.5 22c-10 25.25-7.75 85.25-7.75 113.25s-2.25 88 7.75 113.25c3.5 8.75 7.75 15.25 14.5 22s13.25 11 22 14.5c25.25 10 85.25 7.75 113.25 7.75s88 2.25 113.25-7.75c8.75-3.5 15.25-7.75 22-14.5s11-13.25 14.5-22c10-25.25 7.75-85.25 7.75-113.25s2.25-88-7.75-113.25c-3.5-8.75-7.75-15.25-14.5-22s-13.25-11-22-14.5c-25.25-10-85.25-7.75-113.25-7.75zM384 224c0 26.5 0.25 52.75-1.25 79.25-1.5 30.75-8.5 58-31 80.5s-49.75 29.5-80.5 31c-26.5 1.5-52.75 1.25-79.25 1.25s-52.75 0.25-79.25-1.25c-30.75-1.5-58-8.5-80.5-31s-29.5-49.75-31-80.5c-1.5-26.5-1.25-52.75-1.25-79.25s-0.25-52.75 1.25-79.25c1.5-30.75 8.5-58 31-80.5s49.75-29.5 80.5-31c26.5-1.5 52.75-1.25 79.25-1.25s52.75-0.25 79.25 1.25c30.75 1.5 58 8.5 80.5 31s29.5 49.75 31 80.5c1.5 26.5 1.25 52.75 1.25 79.25z"></path>
    </svg>
  );
};

export default Instagram;
