import React from "react";
import clsx from "clsx";

const LinkedIn = ({ className, ...props }) => {
  return (
    <svg
      className={clsx("icon", "icon--linked-in", className)}
      viewBox="0 0 21 21"
      {...props}
    >
      <path d="M16.625 0H4.375C1.95913 0 0 1.95913 0 4.375V16.625C0 19.0409 1.95913 21 4.375 21H16.625C19.0418 21 21 19.0409 21 16.625V4.375C21 1.95913 19.0418 0 16.625 0ZM7 16.625H4.375V7H7V16.625ZM5.6875 5.8905C4.84225 5.8905 4.15625 5.19925 4.15625 4.347C4.15625 3.49475 4.84225 2.8035 5.6875 2.8035C6.53275 2.8035 7.21875 3.49475 7.21875 4.347C7.21875 5.19925 6.53363 5.8905 5.6875 5.8905ZM17.5 16.625H14.875V11.7215C14.875 8.7745 11.375 8.99762 11.375 11.7215V16.625H8.75V7H11.375V8.54438C12.5965 6.28163 17.5 6.1145 17.5 10.7109V16.625Z"></path>
    </svg>
  );
};

export default LinkedIn;
