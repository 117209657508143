import React from "react";
import clsx from "clsx";

const Search = ({ className, ...props }) => {
  return (
    <svg
      className={clsx("icon", "icon--search", className)}
      viewBox="0 0 22 21"
      {...props}
    >
      <path d="M13.1780425,8.62776285 C13.1780425,6.09227602 11.0962547,3.99823156 8.4856883,3.99823156 C5.87512185,3.99823156 3.79333408,6.09227602 3.79333408,8.62776285 C3.79333408,11.1632497 5.87512185,13.2572941 8.4856883,13.2572941 C11.0962547,13.2572941 13.1780425,11.1632497 13.1780425,8.62776285 Z M15.7731146,13.0525692 L21.0307833,18.3029932 L18.3505462,20.9873392 L13.1752483,15.8191725 C11.8295605,16.7275507 10.2159156,17.2555257 8.4856883,17.2555257 C3.81875171,17.2555257 0,13.4142908 0,8.62776285 C0,3.84123491 3.81875171,0 8.4856883,0 C13.1526249,0 16.9713766,3.84123491 16.9713766,8.62776285 C16.9713766,10.2485676 16.5335102,11.760983 15.7731146,13.0525692 Z"></path>
    </svg>
  );
};

export default Search;
