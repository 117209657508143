import React from "react";
import clsx from "clsx";

const Question = ({ className, ...props }) => {
  return (
    <svg
      className={clsx("icon", "icon--question", className)}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M511.51393 63.894216c-114.5315 0-229.061976 43.508933-316.720432 131.167389-174.676321 174.676321-174.676321 458.764542 0 633.440863 87.658456 87.658456 202.188932 131.167389 316.720432 131.167389s229.062999-43.508933 316.720432-131.167389c174.676321-174.676321 174.676321-458.764542 0-633.440863C740.576929 107.403149 626.045429 63.894216 511.51393 63.894216zM511.51393 108.683305c52.466955 0 103.653755 10.237155 151.641699 29.432333 49.907667 20.475334 94.695732 49.907667 133.086088 88.937588 39.029922 38.390355 68.463278 83.179444 88.937588 133.086088 19.195178 47.987944 29.432333 99.174744 29.432333 151.641699s-10.237155 103.653755-29.432333 151.641699c-20.47431 49.907667-49.907667 94.695732-88.937588 133.086088-38.390355 39.029922-83.179444 68.463278-133.086088 88.937588-47.987944 19.195178-99.174744 29.432333-151.641699 29.432333-52.466955 0-103.653755-10.237155-151.641699-29.432333-49.907667-20.475334-94.695732-49.907667-133.086088-88.937588-39.029922-38.390355-68.463278-83.179444-88.937588-133.086088-19.195178-47.987944-29.432333-99.174744-29.432333-151.641699s10.237155-103.653755 29.432333-151.641699c20.475334-49.907667 49.907667-94.695732 88.937588-133.086088 38.390355-39.029922 83.179444-68.463278 133.086088-88.937588C407.860175 118.92046 459.046974 108.683305 511.51393 108.683305z" />
      <path d="M511.51393 674.300456c-18.555611 0-34.55091 15.355733-34.55091 34.55091 0 18.555611 15.996322 33.911344 34.55091 33.911344 19.195178 0 34.55091-15.355733 34.55091-33.911344C546.065863 689.657212 530.709107 674.300456 511.51393 674.300456zM512.154519 280.799314c-42.2298 0-76.141144 10.877745-101.734032 33.911344-25.593911 22.394033-41.589211 55.026244-46.707788 97.895611l58.225099 7.0383c4.479011-31.991622 15.355733-55.665811 31.352055-70.381977 15.996322-15.355733 35.831067-22.394033 59.505255-22.394033 24.953322 0 46.068222 8.317433 63.343677 24.953322 16.635889 15.996322 24.953322 35.1915 24.953322 57.585533 0 11.517311-2.559289 23.034623-8.317433 33.271778-5.758144 10.237155-18.555611 23.674189-37.750789 40.949644-19.834744 17.275455-33.271778 30.072923-40.949644 39.029922-9.597589 12.156878-17.275455 24.313755-21.754466 36.470633-6.398734 15.996322-9.597589 35.1915-9.597589 56.945967l0.639566 17.275455 53.746088 0c0.639566-22.394033 1.919722-39.029922 4.479011-48.627511 3.198855-10.237155 7.0383-19.195178 12.796444-26.873044 5.758144-7.677866 17.275455-19.834744 35.831067-35.831067 26.873044-24.313755 45.428655-44.789089 55.026244-61.424978 8.958022-16.635889 14.0766-35.1915 14.0766-55.026244 0-34.55091-13.437034-63.984267-40.310078-88.298022C592.773651 292.956192 556.942585 280.799314 512.154519 280.799314z" />
    </svg>
  );
};

export default Question;
