import { createTheme } from "@mui/material";
import sharedTheme, { PRIMARY_COLOR } from "components/theme";
import { colors, mobileBP } from "./consts";

// mobile media query
const mobileMQ = sharedTheme.breakpoints.down(mobileBP);

const theme = createTheme(sharedTheme, {
  // ...sharedTheme,
  palette: {
    // same primary, secondary as sharedTHeme
    primaryFaded: "rgba(226, 64, 0, 0.12)",
    green: "#2c897f", // terciary theme color
    white: "#ffffff",
    purple: "#434878",
    purpleDark: "#111430",
    orange: "#e24000",
    blueLight: "#95aabc",
    gray: "#f4f7f9",
    grayLight: "#eeeeee",
    grayDark: "#7d7d7d",
    grayDarker: "#272727",
  },
  typography: {
    body1: {
      letterSpacing: "0.18px",
      [mobileMQ]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    body2: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    // // define a custom typ. variant for use in table cells, legends etc
    // figure: {
    //   fontSize: "17px",
    //   lineHeight: "21px",
    //   [mobileMQ]: {
    //     fontSize: "16px",
    //   },
    // },
    caption: {
      lineHeight: 1,
      fontSize: "14px",
      letterSpacing: "0.14px",
    },
    h2: {
      fontSize: "36px",
      lineHeight: "42px",
      fontFamily: "GT-Eesti-Text-Bold",
      color: PRIMARY_COLOR,
      [mobileMQ]: {
        fontSize: "24px",
        lineHeight: "29px",
      },
    },
    h3: {
      fontSize: "18px",
      lineHeight: "18px",
    },
    a: {
      color: PRIMARY_COLOR,
      textDecoration: "none",
      cursor: "pointer",
    },
    chartAxisLabel: {
      fontFamily: "GT-Eesti-Text-Bold",
      letterSpacing: "0.18px",
      fontSize: "15px",
      fill: "#696969",
      [mobileMQ]: {
        fontSize: "14px",
      },
    },
    chartTooltipHeading: {
      fontFamily: "GT-Eesti-Text-Bold",
      letterSpacing: "0.18px",
      fontSize: "15px",
      fill: "#fff",
      [mobileMQ]: {
        fontSize: "14px",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        // this won't merge in automatically since it's a template string
        // and we don't want to overwrite it
        ${sharedTheme.components.MuiCssBaseline.styleOverrides}

        ${mobileMQ} {
          // mobile version of a section style set in sharedTheme
          section {
            padding: 51px 0px;
          }
        }

        .tooltip__root {
          padding: 16px !important;
          line-height: 1.5 !important;
        }

        .MuiToggleButtonGroup-root .MuiButtonBase-root {
          &.Mui-selected, &.Mui-selected:hover {
            background: transparent;
            span {
              border: solid 2.5px ${PRIMARY_COLOR};
            }
          }

        }
      `,
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          cursor: "pointer",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
  },
});

const iframedBlogVizTheme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        // this won't merge in automatically since it's a template string
        // and we don't want to overwrite it
        ${theme.components.MuiCssBaseline.styleOverrides}
        
        body {
          // so that iframed blog visuals won't get a scroll bar when we slice off the
          // bottom (otherwise the height of iframe and body has to match perfectly)
          overflow: hidden;
        }
        `,
    },
  },
});

export { iframedBlogVizTheme };

export default theme;
