import {
  Grid,
  Toolbar,
  Typography,
  ButtonBase,
  useScrollTrigger,
} from "@mui/material";
import {
  useLang,
  useDashboardStore,
  useLoadLanguage,
  useSetLanguage,
} from "@hyperobjekt/react-dashboard";
import { useState, useEffect } from "react";
import clsx from "clsx";
import { Menu, Close, ArrowRight } from "../Icons";
import { BASE_ASSET_PATH } from "../../utils";
import HeaderStyles, { EmbedHeaderStyle, MenuExpanded } from "./Header.style";
import "./styles.css";

function Header({ actions = [], withAssetPrefix = x => x, ...props }) {
  const loadLanguage = useLoadLanguage();
  const setLanguage = useSetLanguage();

  useEffect(() => {
    loadLanguage("en", withAssetPrefix("/locales/en/index.json"));
    loadLanguage("es", withAssetPrefix("/locales/es/index.json"));
    setLanguage("en");
  }, [loadLanguage, setLanguage, withAssetPrefix]);

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    // FF on Android can't scroll up to 0 (min is ~.3px), so gets stuck w/ threshold = 0
    threshold: 1,
  });

  const [
    siteTitle,
    menuLabel,
    closeLabel,
    siteHomeLabel,
    latestDataLabel,
    evictionTrackingSystemLabel,
    newestFindingsAndStoriesLabel,
    allUpdatesLabel,
    blogLabel,
    researchLabel,
    reportingLabel,
    nationalDataLabel,
    nationalEvictionMapLabel,
    evictionRankingsLabel,
    aboutTheLabLabel,
    missionAndTeamLabel,
    whyEvictionMattersLabel,
    methodsLabel,
    faqLabel,
  ] = useLang([
    "HEADER_TITLE",
    "HEADER_MENU",
    "HEADER_CLOSE",
    "SITE_HOME",
    "HEADER_LATEST_DATA",
    "HEADER_EVICTION_TRACKING_SYSTEM",
    "HEADER_NEWEST_FINDINGS_AND_STORIES",
    "SITE_UPDATES",
    "HEADER_BLOG",
    "HEADER_RESEARCH",
    "HEADER_REPORTING",
    "HEADER_NATIONAL_DATA",
    "SITE_MAP",
    "SITE_RANKINGS",
    "SITE_ABOUT",
    "HEADER_MISSION_AND_TEAM",
    "SITE_WHY_MATTERS",
    "SITE_METHODS",
    "SITE_FAQ",
  ]);

  const embed = useDashboardStore(state => state.embed);
  const href = !embed
    ? "/"
    : window.location.href.replace("&embed=true", "").replace("&embed", "");

  const [expanded, setExpanded] = useState(false);

  const logoLink = (
    <a className="header__logo" href={href}>
      <img
        className="header__logo-image"
        src={BASE_ASSET_PATH + "/assets/images/logo.svg"}
        alt={siteTitle}
      />
    </a>
  );

  if (embed) {
    return <EmbedHeaderStyle>{logoLink}</EmbedHeaderStyle>;
  }

  // tabIndex for menu items (don't tab through if not open)
  const TI = expanded ? 0 : -1;
  return (
    <>
      <HeaderStyles
        color="default"
        className={clsx("header__root", {
          "header__root--condensed": isScrolled,
        })}
        position="fixed"
        {...props}
      >
        <Toolbar
          className="header__toolbar body__content"
          sx={{ paddingRight: { md: 2 } }}
        >
          <div className="header__title">{logoLink}</div>
          <div className="header__actions">
            {actions}
            <ButtonBase
              className="header__menu-button"
              onClick={() => setExpanded(true)}
            >
              <Menu />
              <Typography
                component="span"
                variant="button"
                fontFamily="GT-Eesti-Text-Bold, sans-serif"
                fontSize="14px"
                letterSpacing=".81052632px"
                lineHeight="1"
                position="relative"
                top="1px"
              >
                {menuLabel}
              </Typography>
            </ButtonBase>
          </div>
        </Toolbar>
      </HeaderStyles>
      <MenuExpanded
        style={{
          transform: `translateY(${expanded ? 0 : "calc(-100% - 5px)"})`,
        }}
      >
        <ButtonBase
          className="app-menu__close-button"
          onClick={() => setExpanded(false)}
        >
          <Close />
          <Typography component="span" variant="button">
            {closeLabel}
          </Typography>
        </ButtonBase>
        <div className="site-navigation" tabIndex={-1}>
          <Grid container sx={{ justifyContent: "center" }} spacing={2}>
            <Grid
              item
              container
              xs={12}
              rowSpacing={4}
              columnSpacing={4}
              className="navGridContainer"
            >
              <Grid item xs={12} sx={{ position: "relative", top: "1rem" }}>
                <a tabIndex={TI} href="https://evictionlab.org">
                  {siteHomeLabel}
                </a>
              </Grid>
              <Grid item xs={12} sm2={6} md={3}>
                <div className="app-menu__section-title">{latestDataLabel}</div>
                <Grid container spacing={{ xs: 1, sm2: 2 }}>
                  <Grid item xs={12}>
                    <a
                      tabIndex={TI}
                      href="https://evictionlab.org/eviction-tracking"
                    >
                      {evictionTrackingSystemLabel}
                    </a>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <a tabIndex={TI} href="https://evictionlab.org/covid-eviction-policies">
                      {policiesDatabaseLabel}
                    </a>
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <a tabIndex={TI} href="https://evictionlab.org/covid-policy-scorecard">
                      {policyScorecardLabel}
                    </a>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12} sm2={6} md={3}>
                <div className="app-menu__section-title">
                  {newestFindingsAndStoriesLabel}
                </div>
                <Grid container spacing={{ xs: 1, sm2: 2 }}>
                  <Grid item xs={12} mb={{ md: "12px" }}>
                    <a tabIndex={TI} href="/research">
                      {researchLabel}
                    </a>
                  </Grid>
                  <Grid item xs={12} mb={{ md: "12px" }}>
                    <a tabIndex={TI} href="/updates">
                      {allUpdatesLabel}
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm2={6} md={3}>
                <div className="app-menu__section-title">
                  {nationalDataLabel}
                </div>
                <Grid container spacing={{ xs: 1, sm2: 2 }}>
                  <Grid item xs={12} mb={{ md: "12px" }}>
                    <a tabIndex={TI} href="https://evictionlab.org/map">
                      {nationalEvictionMapLabel}
                    </a>
                  </Grid>
                  <Grid item xs={12} mb={{ md: "12px" }}>
                    <a tabIndex={TI} href="https://evictionlab.org/rankings">
                      {evictionRankingsLabel}
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm2={6} md={3}>
                <div className="app-menu__section-title">
                  {aboutTheLabLabel}
                </div>
                <Grid container spacing={{ xs: 1, sm2: 2 }}>
                  <Grid item xs={12} mb={{ md: "12px" }}>
                    <a tabIndex={TI} href="https://evictionlab.org/about">
                      {missionAndTeamLabel}
                    </a>
                  </Grid>
                  <Grid item xs={12} mb={{ md: "12px" }}>
                    <a
                      tabIndex={TI}
                      href="https://evictionlab.org/why-eviction-matters"
                    >
                      {whyEvictionMattersLabel}
                    </a>
                  </Grid>
                  <Grid item xs={12} mb={{ md: "12px" }}>
                    <a tabIndex={TI} href="https://evictionlab.org/methods">
                      {methodsLabel}
                    </a>
                  </Grid>
                  <Grid item xs={12} mb={{ md: "12px" }}>
                    <a tabIndex={TI} href="https://evictionlab.org/help-faq">
                      {faqLabel}
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </MenuExpanded>
    </>
  );
}

export default Header;
