exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-gentrification-2-index-js": () => import("./../../../src/pages/blog/gentrification2/index.js" /* webpackChunkName: "component---src-pages-blog-gentrification-2-index-js" */),
  "component---src-pages-blog-gentrification-index-js": () => import("./../../../src/pages/blog/gentrification/index.js" /* webpackChunkName: "component---src-pages-blog-gentrification-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-public-housing-and-the-threat-of-eviction-vis-js": () => import("./../../../src/pages/blog/public-housing-and-the-threat-of-eviction-vis.js" /* webpackChunkName: "component---src-pages-blog-public-housing-and-the-threat-of-eviction-vis-js" */),
  "component---src-pages-eviction-tracking-js": () => import("./../../../src/pages/eviction-tracking.js" /* webpackChunkName: "component---src-pages-eviction-tracking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */)
}

