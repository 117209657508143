import React from "react";
import clsx from "clsx";

const WarningHollow = ({ className, ...props }) => {
  return (
    <svg
      className={clsx(
        "icon",
        "icon--warning",
        "icon--warning-hollow",
        className
      )}
      viewBox="0 0 15 16"
      {...props}
    >
      <path
        d="M8.316 4.441L8.316 10H6.684L6.684 4.441H8.316ZM7.483 10.612C7.74367 10.612 7.959 10.6743 8.129 10.799C8.299 10.9237 8.384 11.122 8.384 11.394C8.384 11.7 8.299 11.904 8.129 12.006C7.959 12.1193 7.74367 12.176 7.483 12.176C7.25633 12.176 7.058 12.1193 6.888 12.006C6.70667 11.904 6.616 11.7 6.616 11.394C6.616 11.1107 6.70667 10.9067 6.888 10.782C7.058 10.6687 7.25633 10.612 7.483 10.612ZM7.5 0.208C8.52 0.208 9.47767 0.400667 10.373 0.786C11.257 1.16 12.039 1.68133 12.719 2.35C13.3877 3.03 13.9147 3.812 14.3 4.696C14.674 5.59133 14.861 6.549 14.861 7.569C14.861 8.589 14.674 9.541 14.3 10.425C13.9147 11.3203 13.3877 12.1023 12.719 12.771C12.039 13.451 11.257 13.978 10.373 14.352C9.47767 14.7373 8.52 14.93 7.5 14.93C6.48 14.93 5.528 14.7373 4.644 14.352C3.74867 13.978 2.96667 13.451 2.298 12.771C1.618 12.1023 1.091 11.3203 0.716999 10.425C0.331666 9.541 0.139 8.589 0.139 7.569C0.139 6.549 0.331666 5.59133 0.716999 4.696C1.091 3.812 1.618 3.03 2.298 2.35C2.96667 1.68133 3.74867 1.16 4.644 0.786C5.528 0.400667 6.48 0.208 7.5 0.208ZM7.5 1.721C6.684 1.721 5.92467 1.86833 5.222 2.163C4.51933 2.469 3.90167 2.88833 3.369 3.421C2.825 3.965 2.40567 4.58833 2.111 5.291C1.805 6.005 1.652 6.76433 1.652 7.569C1.652 8.385 1.805 9.14433 2.111 9.847C2.40567 10.5497 2.825 11.1673 3.369 11.7C3.90167 12.244 4.525 12.6633 5.239 12.958C5.94167 13.264 6.69533 13.417 7.5 13.417C8.316 13.417 9.07533 13.264 9.778 12.958C10.4807 12.6633 11.104 12.244 11.648 11.7C12.1807 11.1673 12.6 10.544 12.906 9.83C13.2007 9.12733 13.348 8.37367 13.348 7.569C13.348 6.753 13.2007 5.99367 12.906 5.291C12.6 4.58833 12.1807 3.965 11.648 3.421C11.104 2.88833 10.4807 2.469 9.778 2.163C9.064 1.86833 8.30467 1.721 7.5 1.721Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningHollow;
