import { createTheme } from "@mui/material";
import { BASE_ASSET_PATH } from "../utils";
import { ArrowDown } from "./Icons";

export const BODY_FONT_FAMILY = "'Akkurat-Regular', sans-serif";
export const BOLD_FONT_FAMILY = "'Akkurat-Bold', sans-serif";
export const HEADING_FONT_FAMILY = "GT-Eesti-Text-Bold, sans-serif";
export const NUMBER_FONT_FAMILY = '"Gotham A", "Gotham B", sans-serif';
export const TEXT_PRIMARY = "#050403";
export const TEXT_SECONDARY = "#737373";
export const PRIMARY_COLOR = "#e24000";
export const SECONDARY_COLOR = "#434878";
export const TERTIARY_COLOR = "#2c897f";
export const QUATERNARY_COLOR = "#94aabd";
export const ALT_BACKGROUND = "#F4F7F9";

const themeConfig = {
  breakpoints: {
    values: {
      xs: 0,
      xs2: 480,
      sm: 600,
      sm2: 768,
      sm3: 992,
      md: 1024,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#e24000",
    },
    secondary: {
      main: "#434878",
    },
    text: {
      primary: "#272727",
      secondary: "#737373",
    },
  },
  typography: {
    fontFamily: BODY_FONT_FAMILY,
    h1: {
      fontFamily: HEADING_FONT_FAMILY,
      fontSize: `${26 / 16}em`,
      letterSpacing: "1.08px",
    },
    h2: {
      fontFamily: HEADING_FONT_FAMILY,
      fontSize: "36px",
      lineHeight: "42px",
      letterSpacing: "0.34px",
    },
    h3: {
      fontFamily: HEADING_FONT_FAMILY,
      fontSize: "22px",
      lineHeight: "22px",
      letterSpacing: "0.22px",
    },
    // unused
    // h4: {
    //   fontFamily: HEADING_FONT_FAMILY,
    //   fontSize: `${16 / 16}em`,
    //   letterSpacing: "0.04em",
    // },
    // h5: {
    //   fontFamily: HEADING_FONT_FAMILY,
    //   fontSize: `${14 / 16}px`,
    //   letterSpacing: "0.04em",
    // },
    // h6: {
    //   fontFamily: HEADING_FONT_FAMILY,
    //   fontSize: `${12 / 16}px`,
    //   letterSpacing: "0.04em",
    // },
    body1: {
      fontFamily: BODY_FONT_FAMILY,
      lineHeight: "27px",
      fontSize: "18px",
      letterSpacing: "0.005em",
    },
    button: {
      fontFamily: HEADING_FONT_FAMILY,
      letterSpacing: "0.05em",
      fontWeight: 400,
    },
    overline: {
      color: TEXT_SECONDARY,
      fontSize: "0.875rem",
      letterSpacing: "0.04em",
    },
  },
  shape: {
    borderRadius: 2,
    contentMaxWidth: "1248px",
  },
  shadows: [
    "none",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
    "0 3px 6px 0 rgb(145 145 145 / 23%)",
  ],
};

// create a base so we can use colors in overrides
const baseTheme = createTheme(themeConfig);

const theme = createTheme({
  ...themeConfig,
  components: {
    MuiCssBaseline: {
      styleOverrides: `@font-face {
        font-family: "Akkurat-Bold";
        src: url("${BASE_ASSET_PATH}/assets/fonts/lineto-akkurat-bold.eot");
        src: url("${BASE_ASSET_PATH}/assets/fonts/lineto-akkurat-bold.eot?#iefix")
            format("embedded-opentype"),
          url("${BASE_ASSET_PATH}/assets/fonts/lineto-akkurat-bold.woff2") format("woff2"),
          url("${BASE_ASSET_PATH}/assets/fonts/lineto-akkurat-bold.woff") format("woff");
        font-weight: normal;
        font-style: normal;
      }
      
      @font-face {
        font-family: "Akkurat-Regular";
        src: url("${BASE_ASSET_PATH}/assets/fonts/lineto-akkurat-regular.eot");
        src: url("${BASE_ASSET_PATH}/assets/fonts/lineto-akkurat-regular.eot?#iefix")
            format("embedded-opentype"),
          url("${BASE_ASSET_PATH}/assets/fonts/lineto-akkurat-regular.woff2") format("woff2"),
          url("${BASE_ASSET_PATH}/assets/fonts/lineto-akkurat-regular.woff") format("woff");
        font-weight: normal;
        font-style: normal;
      }
      
      @font-face {
        font-family: "GT-Eesti-Text-Bold";
        src: url("${BASE_ASSET_PATH}/assets/fonts/GT-Eesti-Display-Bold.eot");
        src: url("${BASE_ASSET_PATH}/assets/fonts/GT-Eesti-Display-Bold.eot?#iefix")
            format("embedded-opentype"),
          url("${BASE_ASSET_PATH}/assets/fonts/GT-Eesti-Display-Bold.woff2") format("woff2"),
          url("${BASE_ASSET_PATH}/assets/fonts/GT-Eesti-Display-Bold.woff") format("woff");
        font-weight: normal;
        font-style: normal;
      }
      
      @font-face {
        font-family: "GT-Eesti-Text-Medium";
        src: url("${BASE_ASSET_PATH}/assets/fonts/GT-Eesti-Display-Medium.eot");
        src: url("${BASE_ASSET_PATH}/assets/fonts/GT-Eesti-Display-Medium.eot?#iefix")
            format("embedded-opentype"),
          url("${BASE_ASSET_PATH}/assets/fonts/GT-Eesti-Display-Medium.woff2") format("woff2"),
          url("${BASE_ASSET_PATH}/assets/fonts/GT-Eesti-Display-Medium.woff") format("woff");
        font-weight: normal;
        font-style: normal;
      }
      
      @font-face {
        font-family: "Gotham-Bold";
        src: url("${BASE_ASSET_PATH}/assets/fonts/Gotham-Bold.woff2") format("woff2");
        font-weight: normal;
        font-style: normal;
      }
      
      section {
        width: 100%;
        max-width: 1024px;
        position: relative;
        padding: 80px 0px;
      }
      
      section:before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 100vw;
        height: 100%;
        z-index: -1;
        margin: 0 calc(-50vw + 50%);
      }
      
      section:nth-child(odd):before {
        background-color: white;
      }
      
      section:nth-child(even):before {
        background-color: ${ALT_BACKGROUND};
      }`,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#efefef",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ArrowDown,
      },
      styleOverrides: {
        icon: {
          width: 12,
          right: 16,
          top: `calc(50% - 0.225em)`,
          color: baseTheme.palette.primary.main,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          "&:not(.MuiBackdrop-invisible)": {
            backdropFilter: "blur(4px)",
            background: "rgb(246, 246, 246, 0.67)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {},
        containedPrimary: {
          fontFamily: HEADING_FONT_FAMILY,
          letterSpacing: "0.05em",
          fontWeight: 400,
        },
        containedGrey: {
          border: `1px solid ${baseTheme.palette.grey[300]}`,
        },
      },
      variants: [
        {
          props: { variant: "bordered" },
          style: {
            border: `5px solid ${ALT_BACKGROUND}`,
            color: TEXT_SECONDARY,
            "&:hover": {
              color: TEXT_PRIMARY,
              backgroundColor: ALT_BACKGROUND,
            },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "bordered" },
          style: {
            borderRadius: 0,
            border: `5px solid ${ALT_BACKGROUND}`,
            color: TEXT_SECONDARY,
            height: 56,
            width: 56,
            "&:hover": {
              color: TEXT_PRIMARY,
            },
          },
        },
      ],
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontFamily: BOLD_FONT_FAMILY,
          textTransform: "uppercase",
          letterSpacing: "0.04em",
          color: TEXT_SECONDARY,
          textAlign: "center",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
          background: "rgba(0,0,0,0.87)",
        },
        arrow: {
          color: "rgba(0,0,0,0.87)",
        },
      },
    },

    MuiTypography: {
      variants: [
        {
          props: { variant: "caption" },
          style: {
            ...baseTheme.typography.caption,
            fontSize: "16px",
            color: "#7D7D7D",
          },
        },
      ],
    },

    // MuiTypography: {
    //   variants: [
    //     {
    //       props: { variant: "captionBold" },
    //       style: {
    //         ...baseTheme.typography.caption,
    //         fontFamily: BOLD_FONT_FAMILY,
    //       },
    //     },
    //     {
    //       props: { variant: "parentName" },
    //       style: {
    //         ...baseTheme.typography.body2,
    //         fontFamily: BOLD_FONT_FAMILY,
    //         textTransform: "uppercase",
    //         letterSpacing: "0.03em",
    //       },
    //     },
    //     {
    //       props: { variant: "number" },
    //       style: {
    //         fontFamily: NUMBER_FONT_FAMILY,
    //       },
    //     },
    //     {
    //       props: { variant: "selectHint" },
    //       style: {
    //         ...baseTheme.typography.caption,
    //         display: "block",
    //         padding: baseTheme.spacing(2, 2, 0, 2),
    //         maxWidth: baseTheme.spacing(35),
    //         color: TEXT_SECONDARY,
    //       },
    //     },
    //   ],
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      sm2: 768,
      sm3: 992,
      md: 1024,
      lg: 1280,
      xl: 1536,
    },
  },
});

export default theme;
