import React from "react";
import clsx from "clsx";

const CircledClose = ({ className, ...props }) => {
  return (
    <svg
      className={clsx("icon", "icon--close", className)}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8369 13.0461L5.0332 6.24246L6.23593 5.03973L13.0396 11.8434L11.8369 13.0461Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.03462 11.8435L11.8383 5.03979L13.041 6.24252L6.23735 13.0462L5.03462 11.8435Z"
        fill="#757575"
      />
      <circle cx="9" cy="9" r="8.5" stroke="#757575" />
    </svg>
  );
};

export default CircledClose;
