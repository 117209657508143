import clsx from "clsx";

const ArrowFilter = ({ className, ...props }) => {
  return (
    <svg
      className={clsx("icon", "icon--arrow-filter", className)}
      viewBox="0 0 10 4"
      {...props}
    >
      <path d="M5.12383 4L0.642147 0.249999L9.60551 0.25L5.12383 4Z" />
    </svg>
  );
};

export default ArrowFilter;
