import React from "react";
import clsx from "clsx";

const Twitter = ({ className, ...props }) => {
  return (
    <svg
      className={clsx("icon", "icon--twitter", className)}
      viewBox="0 0 21 17"
      {...props}
    >
      <path d="M21,2 C20.2270554,2.35 19.3938815,2.58 18.5305927,2.69 C19.4139579,2.16 20.0965583,1.32 20.417782,0.31 C19.584608,0.81 18.6610899,1.16 17.6873805,1.36 C16.8943595,0.5 15.7801147,0 14.5152964,0 C12.1563098,0 10.2289675,1.92 10.2289675,4.29 C10.2289675,4.63 10.2691205,4.96 10.3393881,5.27 C6.76577438,5.09 3.58365201,3.38 1.46558317,0.79 C1.09416826,1.42 0.883365201,2.16 0.883365201,2.94 C0.883365201,4.43 1.63623327,5.75 2.80066922,6.5 C2.08795411,6.5 1.42543021,6.3 0.843212237,6 C0.843212237,6 0.843212237,6 0.843212237,6.03 C0.843212237,8.11 2.32887189,9.85 4.29636711,10.24 C3.93499044,10.34 3.55353728,10.39 3.16204589,10.39 C2.89101338,10.39 2.61998088,10.36 2.35898662,10.31 C2.90105163,12 4.47705545,13.26 6.37428298,13.29 C4.90869981,14.45 3.05162524,15.13 1.02390057,15.13 C0.682600382,15.13 0.341300191,15.11 0,15.07 C1.90726577,16.29 4.17590822,17 6.60516252,17 C14.5152964,17 18.8618547,10.46 18.8618547,4.79 C18.8618547,4.6 18.8618547,4.42 18.8518164,4.23 C19.6950287,3.63 20.417782,2.87 21,2 Z"></path>
    </svg>
  );
};

export default Twitter;
