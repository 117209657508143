import { styled, CssBaseline, ThemeProvider } from "@mui/material";
import Footer from "components/Footer";
import Header from "components/Header";
import theme from "../../theme";
import { withAssetPrefix } from "gatsby";

const StyledMain = styled("main")(({ theme }) => ({
  // TODO: move styles from index.css?
  marginTop: 80,
  [theme.breakpoints.down("sm")]: {
    marginTop: 56,
  },
}));

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header withAssetPrefix={withAssetPrefix} />
      <StyledMain>{children}</StyledMain>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;
